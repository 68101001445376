<template>
  <!-- 排班管理 -->
  <div class="scheduling_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>排班管理</span>
      </div>

      <div
        class="title_select"
        @click="
          if (templateColumns.length > 0) {
            showTemplatePicker = true;
          }
        "
      >
        <span>{{
          templateColumns.length > 0 ? template.text : "暂无模板"
        }}</span>
      </div>
    </div>

    <div class="scheduling_select">
      <div
        class="select_box"
        @click="
          if (jobsColumns.length > 0) {
            showJobsPicker = true;
          }
        "
      >
        <span class="icon icon-switch"
          ><span class="path1"></span><span class="path2"></span
          ><span class="path3"></span
        ></span>
        {{ jobsColumns.length > 0 ? jobs.text : "暂无岗位" }}
      </div>
      <div class="select_time" @click="showDatePicker = true">
        {{
          `${calendarOptions.params.curYear} 年 ${calendarOptions.params
            .curMonth + 1} 月`
        }}
      </div>
    </div>

    <div class="calendar_box">
      <calendar
        :calendar="calendarOptions"
        :events="events"
        :father="'schedules'"
        @onClickDate="onClickDate"
      ></calendar>
    </div>

    <div class="calendar_bottom">
      <div class="type_box">
        <div
          class="type_item"
          v-for="(item, index) in shiftColumns"
          :key="index"
        >
          <div
            class="item_icon"
            :style="{ 'background-color': item.legendColor }"
          ></div>
          <div class="item_txt">{{ item.name }}</div>
        </div>
      </div>

      <div class="type_select_box">
        <div
          class="select_cashier"
          @click="
            if (cashierColumns.length > 0) {
              showCashierPicker = true;
            }
          "
        >
          {{ cashierColumns.length > 0 ? cashier.name : "暂无人员" }}
          <span class="icon-down" v-if="cashierColumns.length > 0"></span>
        </div>
        <div
          class="select_type"
          @click="
            if (shiftColumns.length > 0) {
              showTypePicker = true;
            }
          "
        >
          {{ shiftColumns.length > 0 ? shifts.text : "暂无班次" }}
          <span class="icon-down" v-show="shiftColumns.length > 0"></span>
        </div>
      </div>

      <!-- <div class="scheduling_btn">
        保 存
      </div> -->
    </div>

    <div class="calendar_bottom_box"></div>

    <div id="clickLoading" v-show="clickLoading">
      <van-loading color="#fff">加载中...</van-loading>
    </div>

    <van-popup v-model="showTypePicker" round position="bottom">
      <van-picker
        title="请选择班次"
        show-toolbar
        :columns="shiftColumns"
        @cancel="showTypePicker = false"
        @confirm="onShiftsConfirm"
      />
    </van-popup>

    <van-popup v-model="showCashierPicker" round position="bottom">
      <van-picker
        show-toolbar
        title="请选择收费员"
        :columns="cashierColumns"
        @cancel="showCashierPicker = false"
        @confirm="onCashierConfirm"
      />
    </van-popup>
    <van-popup v-model="showDatePicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showDatePicker = false"
        @confirm="onDateConfirm"
      />
    </van-popup>

    <van-popup v-model="showJobsPicker" round position="bottom">
      <van-picker
        show-toolbar
        title="请选择岗位"
        :columns="jobsColumns"
        @cancel="showJobsPicker = false"
        @confirm="onJobsConfirm"
      />
    </van-popup>

    <van-popup v-model="showTemplatePicker" round position="bottom">
      <van-picker
        show-toolbar
        title="请选择模板"
        :columns="templateColumns"
        @cancel="showTemplatePicker = false"
        @confirm="onTemplateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
let newDate = new Date();
import { mapState } from "vuex";
import calendar from "../components/Calendar";
export default {
  components: {
    calendar
  },
  data: () => ({
    clickLoading: false, //加载
    showDatePicker: false, //日期选择器

    shifts: {}, //班次
    showTypePicker: false, //班次选择器
    shiftColumns: [], //班次数据列表

    cashier: {}, //收费员
    cashierColumns: [], //收费员数据列表
    showCashierPicker: false, //收费员选择器

    template: {}, //排班模板
    templateColumns: [], //排班模板数据列表
    showTemplatePicker: false, //排班模板选择器

    currentDate: newDate, //当前日期
    minDate: new Date(newDate.getFullYear(), newDate.getMonth() - 12, 1),
    maxDate: new Date(newDate.getFullYear(), newDate.getMonth() + 12, 1),

    events: [], //日历事件标签列表

    undo: false, //是否删除

    //收费岗
    jobs: {
      text: "",
      id: ""
    },
    stationId: null, //收费岗id
    jobsColumns: [], //收费岗选择器
    showJobsPicker: false //收费岗选择器
  }),

  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),

    //日历数据对象
    calendarOptions() {
      let dateObj = this.currentDate;
      return {
        options: {
          weekStartOn: 0
        },
        params: {
          curYear: dateObj.getFullYear(),
          curMonth: dateObj.getMonth(),
          curDate: dateObj.getDate(),
          curEventsDate: "all"
        }
      };
    },

    //请求时间
    requestTime() {
      let date = new Date(this.currentDate);
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;

      console.log(month);

      return `${date.getFullYear()}-${month}-01`;
    }
  },

  methods: {
    /**
     * 日期选择
     * value 日期  Date类型
     */
    onDateConfirm(value) {
      console.log("value ==> ", value);
      this.currentDate = value;
      this.showDatePicker = false;

      this.initData();
    },

    /**
     * vant日期过滤函数
     */
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      } else if (type === "year") {
        return `${val}年`;
      }
      return val;
    },

    /**
     * 点击日历元素
     */
    onClickDate(item) {
      this.clickLoading = true;
      //设置定时器
      clearTimeout(this.dateClickTimer);
      this.dateClickTimer = setTimeout(() => {
        //处理时间格式
        let date = new Date(item.date);
        let dateStr = `${date.getFullYear()}-${
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

        //遍历数组
        for (let k of item.schedules) {
          //当班次、日期、人员全部相同时，执行删除操作
          if (
            k.employeeId == this.cashier.id &&
            new Date(k.shiftDate).getTime() == new Date(item.date).getTime() &&
            k.shiftId == this.shifts.id
          ) {
            // this.$post(
            //   this.$config.TL_SCHEDULE_MANAGEMENT_DELETE_SCHEDULE_MANAGEMENT,
            //   {
            //     tollStationId: this.jobs.id,
            //     tollEmployeeId: this.cashier.id,
            //     shiftDate: dateStr,
            //     shiftId: this.shifts.id
            //   }
            // )
            this.$api
              .deleteScheduleManagement({
                tollStationId: this.jobs.id,
                tollEmployeeId: this.cashier.id,
                shiftDate: dateStr,
                shiftId: this.shifts.id
              })
              .then(() => {
                //前端过滤掉被删除的标签
                this.events = this.events.filter(_ => k != _);
              });

            this.clickLoading = false;
            return;
          }

          //当班次与日期相同时，执行修改操作
          // else if (
          //   new Date(k.shiftDate).getTime() == new Date(item.date).getTime() &&
          //   k.shiftId == this.shifts.id
          // ) {
          //   this.$post(
          //     this.$config.TL_SCHEDULE_MANAGEMENT_DELETE_SCHEDULE_MANAGEMENT,
          //     {
          //       tollStationId: this.jobs.id,
          //       tollEmployeeId: k.employeeId,
          //       shiftDate: dateStr,
          //       shiftId: this.shifts.id,
          //     }
          //   ).then(() => {
          //     //前端过滤掉被删除的标签
          //     this.events = this.events.filter((_) => k != _);

          //     this.addDateSchedule(item.date);
          //   });
          //   this.clickLoading = false ;
          //   return;
          // }
        }

        //当不相同时，执行添加操作
        this.addDateSchedule(item.date);
        this.clickLoading = false;
      }, 500);
    },

    /**
     * 往日历内添加新标签
     */
    addDateSchedule(date) {
      let newDate = new Date(date);
      //补零操作
      let dateStr = `${newDate.getFullYear()}-${
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth() + 1
      }-${
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()
      }`;

      //请求接口添加
      // this.$post(this.$config.TL_SCHEDULE_MANAGEMENT_ADD_SCHEDULE_MANAGEMENT, {
      //   tollStationId: this.jobs.id,
      //   tollEmployeeId: this.cashier.id,
      //   shiftDate: dateStr,
      //   shiftTemplateId: this.template.id,
      //   shiftId: this.shifts.id
      // })
      this.$api
        .addScheduleManagement({
          tollStationId: this.jobs.id,
          tollEmployeeId: this.cashier.id,
          shiftDate: dateStr,
          shiftTemplateId: this.template.id,
          shiftId: this.shifts.id
        })
        .then(() => {
          console.log(this.shifts.sort);
          this.events.push({
            employeeId: this.cashier.id,
            employeeName: this.cashier.name,
            legendColor: this.shifts.legendColor,
            shiftDate: date,
            shiftId: this.shifts.id,
            sort: this.shifts.sort
          });
        });
    },

    /**
     * 确认班次
     */
    onShiftsConfirm(value) {
      this.shifts = value;
      this.showTypePicker = false;
    },

    /**
     * 确认收费员
     */
    onCashierConfirm(value) {
      if (value.undo) {
        this.undo = true;
      } else {
        this.undo = false;
      }
      this.cashier = value;
      this.showCashierPicker = false;
    },

    /**
     * 确认收费岗
     */
    onJobsConfirm(value) {
      this.jobs = value;
      this.showJobsPicker = false;

      this.initData();
    },

    /**
     * 确认排班模板
     */
    onTemplateConfirm(value) {
      this.template = value;
      this.showTemplatePicker = false;
      this.getschedulingInfo();
    },

    /**
     * 获取岗位列表信息
     */
    getJobsInfo(callback) {
      // this.$get(this.$config.TL_CHARGE_STATUS_GET_STATION_BY_CAPTAIN)
      this.$api.getStationByCaptain().then(res => {
        console.log(res);
        this.jobsColumns = [];
        this.jobs = {};
        if (res["data"]["data"] && res["data"]["data"].length > 0) {
          res["data"]["data"].forEach(item => {
            this.jobsColumns.push(item);
          });
          this.jobs = this.jobsColumns[0];

          callback();
        }
      });
    },

    /**
     * 获取排班模板列表
     */
    getTemplateInfo(callback) {
      // this.$get(this.$config.TL_SCHEDULE_MANAGEMENT_GET_TOLL_SHIFT_TEMPLATES, {
      //   stationId: this.jobs.id,
      //   time: this.requestTime
      // })
      this.$api
        .getTeamTollShiftTemplates({
          stationId: this.jobs.id,
          time: this.requestTime
        })
        .then(res => {
          console.log("getTemplateInfo ==>", res);
          this.templateColumns = [];
          this.template = {};
          if (res["data"]["data"] && res["data"]["data"].length > 0) {
            res["data"]["data"].forEach(item => {
              this.templateColumns.push({
                ...item,
                text: item.name
              });
            });

            this.template = this.templateColumns[0];

            callback();
          }
        });
    },

    /**
     * 获取收费员列表
     */
    getschedulingInfo() {
      // this.$get(this.$config.TL_SCHEDULE_MANAGEMENT_EDIT_SCHEDULE_MANEGEMENT, {
      //   time: this.requestTime,
      //   stationId: this.jobs.id,
      //   shiftTemplateId: this.template.id
      // })
      this.$api
        .editScheduleManagement({
          time: this.requestTime,
          stationId: this.jobs.id,
          shiftTemplateId: this.template.id
        })
        .then(res => {
          console.log(res);

          this.events = [];
          if (
            res["data"]["data"]["schedules"] &&
            res["data"]["data"]["schedules"].length > 0
          ) {
            this.events = res["data"]["data"]["schedules"];
          }

          //更新班次列表
          this.shiftColumns = [];
          this.shifts = {};
          if (
            res["data"]["data"]["shifts"] &&
            res["data"]["data"]["shifts"].length > 0
          ) {
            res["data"]["data"]["shifts"].forEach(item => {
              this.shiftColumns.push({
                ...item,
                text: `${item.name} ${item.startTime}-${item.endTime}`
              });
            });
            this.shifts = this.shiftColumns[0];
          }

          //更新收费员列表
          this.cashierColumns = [];
          this.cashier = {};
          if (
            res["data"]["data"]["employees"] &&
            res["data"]["data"]["employees"].length > 0
          ) { 
            res["data"]["data"]["employees"].forEach(item => {
              this.cashierColumns.push({
                ...item,
                text: item.name
              });
            });
            this.cashier = this.cashierColumns[0];
          }
        });
    },

    initData() {
      this.$tools.entering();
      this.getTemplateInfo(() => {
        this.$tools.entering();
        this.getschedulingInfo();
      });
    }
  },

  created() {
    this.getJobsInfo(() => {
      this.initData();
    });   

    this.$tools.entering();
  }
};
</script>

<style lang="less" scoped>
.scheduling_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: scroll;
  background-color: #fff;
}

.scheduling_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 10px;
  border-bottom: 1px solid rgba(7, 17, 27, 0.1);

  .select_box {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
    }
  }

  .select_time {
    font-size: 18px;
    color: #4f545f;
  }
}

.calendar_box {
  // border-bottom : 1px solid rgba(7, 17, 27, 0.1);
}

.calendar_bottom {
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 10vh;
  left: 0;
  background-color: #fff;
  border-top: 1px solid rgba(7, 17, 27, 0.1);
  // box-shadow: 0px 2px 4px #ccc inset;
}

.calendar_bottom_box {
  width: 100%;
  height: 100px;
}

.type_box {
  display: flex;
  justify-content: flex-end;
  padding: 14px;

  .type_item {
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;

    &:last-child {
      margin: 0;
    }

    .item_icon {
      height: 16px;
      width: 40px;
      border-radius: 10px;
      background-color: #ccc;
      margin-right: 10px;

      &.early {
        background-color: #40c057;
      }

      &.noon {
        background-color: #ff9900;
      }

      &.night {
        background-color: #4c6ef5;
      }
    }
  }
}

.type_select_box {
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-bottom: 14px;

  .select_cashier {
    width: 100px;
    font-size: 12px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(7, 17, 27, 0.1);
    box-shadow: 0px 2px 4px #ccc;
  }

  .select_type {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(7, 17, 27, 0.1);
    box-shadow: 0px 2px 4px #ccc;
    font-size: 12px;
    padding: 8px 12px;
  }
}

.scheduling_btn {
  height: 54px;
  font-size: 20px;
  color: #fff;
  background-color: #06338e;
  display: flex;
  justify-content: center;
  align-items: center;
}

#clickLoading {
  display: flex;
  align-items: center;
  // justify-content: center ;
  width: 200px;
  border-radius: 10px;
  border: 1px solid #1989fa;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .van-loading {
    .van-loading__spinner {
      width: 40px;
      height: 40px;
    }
    .van-loading__text {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
